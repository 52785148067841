import React from "react";

import { isAdmin } from "../../Utils/Auth";
import { AccountInfo } from "@azure/msal-common";
import "./style.scss";

import ContainerCustomers from "./Customer/ContainerCustomers";
import { ICustomer } from "../../interfaces/ICustomer";
import ContainerProject from "./Project/ContainerProject";
import ContainerOpenQuote from "./OpenQuote/OpenQuote";
import ContainerTsBooking from "./ContainerTsBooking/ContainerTsBooking";

function HomeContainer(props: { account: AccountInfo | null; customers: ICustomer[] }) {
    let admin = isAdmin(props.account);

    return (
        <>
            <div className="container">
                {admin && (
                    <>
                        <div className="dashboard">
                            <ContainerCustomers classes="ds-width-3" customers={props.customers} />
                            <ContainerProject classes="ds-width-3" customers={props.customers}/>
                            <ContainerOpenQuote classes="ds-width-3"/>
                            <ContainerTsBooking classes="ds-width-3"/>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default HomeContainer;
