import { getToken } from "./Auth";



export async function GetTsApiBarerToken(secret:string): Promise<string> {
    let token = await getToken(secret);
    return token;
    

    /*
    return new Promise<any>(async (resolve, reject) => {
            const response = await getToken(username,password,secret)
            .catch((error: string) => reject(error));
        return response;
    });*/
}


//export default { GetTsApiBarerToken };
