import React from "react";

import { addPhase, addTask, deleteTask } from "../QuoteEditFunctions";
import { IWorkGroup } from "../../../interfaces/IWorkGroup";
import "./style.scss";
import QuoteEditDocumentHeader from "../QuoteEditDocumentHeader/QuoteEditDocumentHeader";
import EditBar from "../EditBar/EditBar";
import QuoteEditPhaseHeader from "../QuoteEditPhaseHeader/QuoteEditPhaseHeader";
import Directory from "../Directory/Directory";
import { secondsToHour } from "../../../Utils/Utilities";
import API from "./../../../api/apiHandler";
import { EMPTY_QUOTE_SUMMARY, IQuoteSummary } from "../../../interfaces/IQuoteSummery";

import { IProjectPhaseOffer } from "../../../interfaces/IProjectPhase";
import { IQuote } from "../../../interfaces/IQuote";
import { ITask, ITaskAddMessage, ITaskChangeMessage } from "../../../interfaces/ITask";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import { IMessage } from "../../../Core/IMessage";
import { InputEventMessage } from "../../../Core/InputEventMessage";
import { IMessageHandler } from "../../../Core/IMessageHandler";
import QuoteEditDocumentBody from "../QuoteEditDocumentBody/QuoteEditDocumentBody";
import { AccountData } from "../../../Utils/Auth";
import { IHistoryInfo } from "../../../interfaces/IHistoryInfo";
import { EMPTY_EMPLOYEE } from "../../../interfaces/IEmployee";
import { DataManagerKeys } from "../../../Utils/DataManagerKeys";
import { DataManager } from "../../../Utils/DataManager";
import { ISettingEnum } from "../../../interfaces/ISetting";
import LoadingContainer from "../../molecules/LoadingContainer/LoadingContainer";
import { TsDataContext } from "../TsDataContext";
import { useContext } from "react";

type Props = {
    id: number;
    version: string;
};
type States = {
    id: number;
    version: string;
    quoteSummary: IQuoteSummary;
    saved: boolean;
    workGroups: IWorkGroup[];
    switchPhases: boolean;
    isLoading: boolean;
    error: boolean;
};

class QuoteEditDocument extends React.Component<Props, States> implements IMessageHandler {
    state: States = {
        id: this.props.id,
        version: this.props.version,
        quoteSummary: EMPTY_QUOTE_SUMMARY,
        saved: true,
        workGroups: [],
        switchPhases: false,
        isLoading: false,
        error: false
    };
    
    
    updateInfo = (_newOffer?: IQuoteSummary): IQuoteSummary => {
        const employee = AccountData.getUser();
        /* convert datetime TODO: refactoring roman*/
        let dtl = new Date().toLocaleString().split(".");
        for (let i = 0; i < 3; i++) {
            if (dtl[i].length < 2) {
                dtl[i] = `0${dtl[i]}`;
            }
        }
        let date = `${dtl[0]}.${dtl[1]}.${dtl[2]}`;

        const newHistroyInfo: IHistoryInfo = {
            DateTime: date,
            Employee: { ...EMPTY_EMPLOYEE, FirstName: employee.givenName || "", LastName: employee.surname || "" },
            HistoryVersion: 0,
            Id: 0
        };
        let newOffer: IQuoteSummary;
        let modified: IHistoryInfo[] = [];

        if (_newOffer) {
            modified = _newOffer.OfferObject.Modified;
            modified.push(newHistroyInfo);
            newOffer = {
                ..._newOffer,
                OfferObject: { ..._newOffer.OfferObject, Modified: modified }
            };
        } else {
            modified.push(newHistroyInfo);
            newOffer = {
                ...this.state.quoteSummary,
                OfferObject: { ...this.state.quoteSummary.OfferObject, Modified: modified }
            };
        }
        return newOffer;
    };

    handleInvoiceTypeChange = (invoiceType: number) => {
        if (!this.state.quoteSummary) return;
        const newOffer: IQuoteSummary = { ...this.state.quoteSummary };
        newOffer.OfferObject.InvoicingType = invoiceType;
        this.updateOfferForceSave(newOffer);
    };

    handleQuoteStateChange = (quoteState: number) => {
        if (!this.state.quoteSummary) return;
        const newOffer: IQuoteSummary = { ...this.state.quoteSummary };
        newOffer.documentState = quoteState;
        this.updateOfferForceSave(newOffer);
    };

    handleQuoteNameChange = (quoteName: string) => {
        if (!this.state.quoteSummary) return;
        const newOffer: IQuoteSummary = { ...this.state.quoteSummary };
        newOffer.name = quoteName;
        console.log(quoteName);
        this.updateOfferForceSave(newOffer);
    };

    updateOfferForceSave = (_newOffer: IQuoteSummary) => {
        this.setState({ quoteSummary: _newOffer, saved: false, isLoading: false });
    };

    updateOffer = (_newOffer: IQuoteSummary) => {
        this.setState({ quoteSummary: _newOffer, isLoading: false });
    };

    OnMessage(message: IMessage<any>) {
        switch (message.Code) {
            case InputEventMessage.KEY_DOWN:
                //CTRL+S

                if (message.Context.ctrlKey && message.Context.key === "s") {
                    this.saveOffer();
                    message.Context.preventDefault();
                }
                break;
            case EventMessage.ADD_PHASE:
                this.handleAddPhase();
                break;
            case EventMessage.EDIT_TASK:
                let editedTask = message.Context as ITaskChangeMessage;
                this.handleChangeTasks(editedTask.task, editedTask.indexPhase, editedTask.indexTask);
                break;
            case EventMessage.ADD_TASK:
                let newTask = message.Context as ITaskAddMessage;
                addTask(this.state.quoteSummary, newTask.phaseIndex, newTask.taskType, this.updateOfferForceSave);
                break;
            case EventMessage.DELETE_TASK:
                deleteTask(
                    this.state.quoteSummary,
                    message.Context.indexPhase,
                    message.Context.indexTask,
                    this.updateOfferForceSave
                );
                break;
            case EventMessage.EDIT_PHASE:
                this.handlePhaseChange(message.Context.phase, message.Context.phaseIndex);
                break;
            case EventMessage.DELETE_PHASE:
                this.handlePhaseDelete(message.Context.phaseId);
                break;
            case EventMessage.EDIT_QUOTE_NAME:
                this.handleQuoteNameChange(message.Context.quoteName);
                break;
        }
    }

    componentDidMount() {
        Message.Subscribe(EventMessage.SAVE_FILE, this);
        Message.Subscribe(EventMessage.ADD_PHASE, this);
        Message.Subscribe(InputEventMessage.KEY_DOWN, this);
        Message.Subscribe(EventMessage.EDIT_TASK, this);
        Message.Subscribe(EventMessage.ADD_TASK, this);
        Message.Subscribe(EventMessage.DELETE_TASK, this);
        Message.Subscribe(EventMessage.EDIT_PHASE, this);
        Message.Subscribe(EventMessage.DELETE_PHASE, this);
        Message.Subscribe(EventMessage.EDIT_QUOTE_NAME, this);

        const getData = async () => {
            this.setState({ isLoading: true });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const versions = await API.Quote.GetVersions(this.state.id).catch((error: string) => {
                this.setState({ error: true });
                console.error(error);
            });

            if (this.state.version !== "-1" && this.state.id > 0) {
                let offer = await API.Quote.GetQuote(this.state.id, this.state.version);

                this.updateOffer(offer);
            }
            let workgroups = await API.Workgroups.getAllWorkgroups();
            this.setState({ workGroups: workgroups });
        };

        getData();
    }

    componentWillUnmount() {
        Message.Unsubscribe(EventMessage.SAVE_FILE, this);
        Message.Unsubscribe(EventMessage.ADD_PHASE, this);
        Message.Unsubscribe(InputEventMessage.KEY_DOWN, this);
        Message.Unsubscribe(EventMessage.EDIT_TASK, this);
        Message.Unsubscribe(EventMessage.ADD_TASK, this);
        Message.Unsubscribe(EventMessage.DELETE_TASK, this);
        Message.Unsubscribe(EventMessage.EDIT_PHASE, this);
        Message.Unsubscribe(EventMessage.DELETE_PHASE, this);
        Message.Unsubscribe(EventMessage.EDIT_QUOTE_NAME, this);
    }

    onDragEnd = (result: any) => {
        const { destination, type, source } = result;
        if (!destination) return;

        //diffrence two types : phase and task

        switch (type) {
            case "phase":
                console.log("phase has been moved");

                //Check for position change
                if (source.index === destination.index) return;

                //change the order of the phase
                if (!this.state.quoteSummary) return;
                const _phaseSource1: IProjectPhaseOffer = {
                    ...this.state.quoteSummary.OfferObject.Phases[source.index]
                };
                const _phases1: IProjectPhaseOffer[] = [...this.state.quoteSummary.OfferObject.Phases];
                _phases1.splice(source.index, 1);
                _phases1.splice(destination.index, 0, _phaseSource1);
                //Create newOffer and set it..
                const newOffer1: IQuote = {
                    ...this.state.quoteSummary.OfferObject,
                    Phases: _phases1
                };
                this.updateOfferForceSave({ ...this.state.quoteSummary, OfferObject: newOffer1 });

                break;
            case "task":
                /*Diffrence two types of moves 
            one move is the same phase, 
            and the other is change the phase to an other */

                //PHASE CHANGED
                if (!this.state.quoteSummary) return;
                //NEED the phase id for indexing
                const phaseIdDestination = parseInt(destination.droppableId.match(/\d+$/)[0], 10);
                const phaseIdSource = parseInt(source.droppableId.match(/\d+$/)[0], 10);

                const _phaseDestination: IProjectPhaseOffer = {
                    ...this.state.quoteSummary.OfferObject.Phases[phaseIdDestination]
                };
                const _phaseSource: IProjectPhaseOffer = {
                    ...this.state.quoteSummary.OfferObject.Phases[phaseIdSource]
                };
                const _taskSource: ITask = {
                    ...this.state.quoteSummary.OfferObject.Phases[phaseIdSource].Tasks[source.index]
                };

                _phaseSource.Tasks.splice(source.index, 1);
                _phaseDestination.Tasks.splice(destination.index, 0, _taskSource);

                const _phases: IProjectPhaseOffer[] = [...this.state.quoteSummary.OfferObject.Phases];
                _phases.splice(phaseIdDestination, 1, _phaseDestination);
                _phases.splice(phaseIdSource, 1, _phaseSource);

                const newOffer: IQuote = {
                    ...this.state.quoteSummary.OfferObject,
                    Phases: _phases
                };

                this.updateOfferForceSave({ ...this.state.quoteSummary, OfferObject: newOffer });
                console.log("task has been moved");
                break;
        }
    };

    handleTaskDelete = (indexPhase: number, indexTask: number) => {
        if (!this.state.quoteSummary) return;
        deleteTask(this.state.quoteSummary, indexPhase, indexTask, this.updateOfferForceSave);
    };

    handlePhaseDelete = (phaseId: string) => {
        if (!this.state.quoteSummary) return;

        let _phases: IProjectPhaseOffer[] = [...this.state.quoteSummary.OfferObject.Phases];
        _phases = _phases.filter((phase: IProjectPhaseOffer) => phase.Id !== phaseId);

        const newOffer: IQuote = {
            ...this.state.quoteSummary.OfferObject,
            Phases: _phases
        };
        this.updateOfferForceSave({ ...this.state.quoteSummary, OfferObject: newOffer });
    };

    handleAddPhase = () => {
        if (!this.state.quoteSummary) return;
        addPhase(this.state.quoteSummary, this.updateOfferForceSave);
    };

    saveOffer = () => {
        if (this.state.quoteSummary) {
            const newOffer = this.updateInfo();
            API.Quote.SaveQuote(newOffer);
            this.setState({ quoteSummary: newOffer, saved: true });
        }
    };

    handleAddTask = (phaseIndex: number, taskType: number) => {
        if (!this.state.quoteSummary) return;
        addTask(this.state.quoteSummary, phaseIndex, taskType, this.updateOfferForceSave);
    };

    handlePhaseChange = (_phase: IProjectPhaseOffer, pPhaseIndex: number) => {
        if (!this.state.quoteSummary) return;
        const _phases: IProjectPhaseOffer[] = [...this.state.quoteSummary.OfferObject.Phases];
        _phases[pPhaseIndex] = _phase;

        const newOffer: IQuote = {
            ...this.state.quoteSummary.OfferObject,
            Phases: _phases
        };
        this.updateOfferForceSave({ ...this.state.quoteSummary, OfferObject: newOffer });
    };

    handleChangeTasks = (_task: ITask, pPhaseIndex: number, pTaskIndex: number) => {
        if (!this.state.quoteSummary) return;

        const _phases: IProjectPhaseOffer[] = [...this.state.quoteSummary.OfferObject.Phases];
        _phases[pPhaseIndex].Tasks[pTaskIndex] = _task;
        const newOffer: IQuote = {
            ...this.state.quoteSummary.OfferObject,
            Phases: _phases
        };
        this.updateOfferForceSave({ ...this.state.quoteSummary, OfferObject: newOffer });
    };

    handleSwitchPhaseChange = () => {
        this.setState({ switchPhases: !this.state.switchPhases });
        let el = document.getElementsByClassName("offerlist--phase");
        if (this.state.switchPhases) {
            Array.from(el).forEach((els) => els.classList.remove("offerlist--phase-minimized"));
        } else {
            Array.from(el).forEach((els) => els.classList.add("offerlist--phase-minimized"));
        }
    };

    render() {
        
        let TempPhases = this.state.quoteSummary?.OfferObject?.Phases || [];

        let quoteStates: ISettingEnum = DataManager.GetValue(DataManagerKeys.TEMPLATE_OFFER_STATES);

        let quoteEditable = quoteStates.Data[this.state.quoteSummary.documentState] === "OPEN";

        // Sum costs
        let sumPhase = TempPhases.map((phase: IProjectPhaseOffer) => {
            return phase.Tasks.map((task) => {
                switch (task.TaskType) {
                    case 0:
                    case 1:
                        return secondsToHour(task.Duration) * task.HourlyRate;
                    case 2:
                        return task.HourlyRate;
                    default:
                        return 0;
                }
            });
        });

        let sumPhase1 = [];
        if (sumPhase.length > 0) {
            sumPhase1 = sumPhase.map((sum: any) => {
                if (sum.length === 0) return 0;
                return sum.reduce((a: any, b: any) => a + b);
            });
        }

        let sumTotal = 0;
        if (sumPhase1.length > 0) {
            sumTotal = sumPhase1.reduce((a: any, b: any) => a + b);
        }

        //sum time
        let sumTime = TempPhases.map((phase: IProjectPhaseOffer) => {
            return phase.Tasks.map((task) => {
                switch (task.TaskType) {
                    case 0:
                    case 1:
                        return secondsToHour(task.Duration);

                    default:
                        return 0;
                }
            });
        });

        let sumTimePhase1 = [];
        if (sumTime.length > 0) {
            sumTimePhase1 = sumTime.map((sum: any) => {
                if (sum.length === 0) return 0;
                return sum.reduce((a: any, b: any) => a + b);
            });
        }

        let sumTimeTotal = 0;
        if (sumTimePhase1.length > 0) {
            sumTimeTotal = sumTimePhase1.reduce((a: any, b: any) => a + b);
        }

        let QuoteId = this.state.quoteSummary?.id || "0";
        
        

        return (
            <>
                {this.state.error && <div className="offerlist--loading-screen">Error</div>}
                {this.state.isLoading && !this.state.error && (
                    <LoadingContainer text="Loading quote data from backend" />
                )}
                {!this.state.isLoading && !this.state.error && this.state.quoteSummary != null && (
                    <div className="container__quote-document">
                        <EditBar
                            offer={this.state.quoteSummary}
                            saveDocument={this.saveOffer}
                            saved={this.state.saved}></EditBar>
                        <div className={`quote-document ${!this.state.saved && "unsaved"}`}>
                            <QuoteEditDocumentHeader
                                offer={this.state.quoteSummary}
                                changeInvoiceType={this.handleInvoiceTypeChange}
                                changeQuoteType={this.handleQuoteStateChange}></QuoteEditDocumentHeader>

                            <div className={`offerlist__item offerlist--list ${!this.state.saved && "unsaved"}`}>
                                <label className="offerlist--phase-switch">
                                    <input type="checkbox" onChange={() => this.handleSwitchPhaseChange()} />
                                    <span className="phase-switch-slider"></span>
                                    <span className="phase-switch-text">Move Phases</span>
                                </label>
                                <QuoteEditPhaseHeader></QuoteEditPhaseHeader>
                                <QuoteEditDocumentBody
                                    quoteEditable={quoteEditable}
                                    workGroups={this.state.workGroups}
                                    switchEnabled={this.state.switchPhases}
                                    customerId={this.state.quoteSummary.Organization.OrganizationId}
                                    projectId={this.state.quoteSummary.Project.ProjectId}
                                    quoteId={QuoteId}
                                    phases={TempPhases}
                                    onDragEnd={this.onDragEnd}></QuoteEditDocumentBody>
                                <div className="quote-footer">
                                    <div className="sum-quote-time"> {sumTimeTotal} h </div>
                                    <div className="sum-quote">Quote total CHF {sumTotal.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                        <Directory offer={this.state.quoteSummary}></Directory>
                    </div>
                )}
            </>
        );
    }
}
export default QuoteEditDocument;
