export const API_BASE_URL = "https://thanosv2admin.azurewebsites.net/api/";
export const API_ADMIN_URL = `${API_BASE_URL}manager/`;
export const API_ADMIN_URL_TEMPLATES = `${API_BASE_URL}templates`;
export const API_SWAGGER_URL = `${API_BASE_URL}swagger/ui`;

//-----------------CS - TimeSafe-----------------
export const API_TIMESAFE_URL = `${API_BASE_URL}TimeSafe/`;
export const API_ORDERING_URL = `${API_TIMESAFE_URL}Ordering/`;
export const API_ORGANIZATION_URL = `${API_TIMESAFE_URL}organizations`;


//-----------------TimeSafe-----------------
export const TSURL_BASE_API_LOCAL = "http://localhost:80"; //TODO: Replace link
export const TSURL_BASE_API_ONLINE = "https://51.107.45.62"; //TODO: Replace link
export const TSURL_BASE_API = TSURL_BASE_API_ONLINE;
export const TSURL_VERSIONSTR = "/api/v1"; //TODO: Replace link
export const TSURL_BASE_W_VERSION = `${TSURL_BASE_API}${TSURL_VERSIONSTR}`;
export const TSURL_TOKEN = `${TSURL_BASE_W_VERSION}/Token`;
export const TSURL_ORGANIZATIONS = `${TSURL_BASE_W_VERSION}/Organizations`;
export const TSURL_BASE_URL = "https://vmthanossecure.net/api/V1";
export const TSURL_ADRESSEN = `${TSURL_BASE_W_VERSION}/Adressen`;
export const TSURL_ANSTELLUNGSVERHAELTNISSE = `${TSURL_BASE_W_VERSION}/Anstellungsverhaeltnisse`;
export const TSURL_FEIERTAGE = `${TSURL_BASE_W_VERSION}/Feiertage`;
export const TSURL_KOSTENTRAEGER = `${TSURL_BASE_W_VERSION}/Kostentraeger`;
export const TSURL_LIZENZINFO = `${TSURL_BASE_W_VERSION}/LizenzInformation`;
export const TSURL_MANDANTENEINSTELLUNGEN = `${TSURL_BASE_W_VERSION}/Mandanteneinstellungen`;
export const TSURL_MITARBEITER = `${TSURL_BASE_W_VERSION}/Mitarbeiter`;
export const TSURL_MITARBEITER_LEISTERFASSUNG = `${TSURL_BASE_W_VERSION}/MitarbeiterLeistungserfassung`;
export const TSURL_PROJEKTE = `${TSURL_BASE_W_VERSION}/Projekte`;
export const TSURL_PROJEKTE_LEISTUNGSERFASSUNG = `${TSURL_BASE_W_VERSION}/ProjekteLeistungserfassung`;
export const TSURL_PROJEKT_TAETIGKEITEN = `${TSURL_BASE_W_VERSION}/ProjektTaetigkeiten`;
export const TSURL_PROJEKT_TEAM_MITGLIEDER = `${TSURL_BASE_W_VERSION}/ProjektTeammitglieder`;
export const TSURL_PROJEKT_TYPEN = `${TSURL_BASE_W_VERSION}/Projekttypen`;
export const TSURL_STAMMDATENTAETIGKEITEN = `${TSURL_BASE_W_VERSION}/StammdatenTaetigkeiten`;
export const TSURL_VERSION = `${TSURL_BASE_W_VERSION}/Version`;
export const TSURL_ZEITEINTRAEGE = `${TSURL_BASE_W_VERSION}/Zeiteintraege`;