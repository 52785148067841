import React, { useContext, useEffect, useState } from "react";
import { ITask } from "../../../interfaces/ITask";

import { Draggable } from "react-beautiful-dnd";
import { secondsToHour, dateStringToInputDate, InputDateTodateString } from "../../../Utils/Utilities";
import { IWorkGroup } from "../../../interfaces/IWorkGroup";
import "./style.scss";
import { MdExpandLess } from "react-icons/md";
import { FaTrashAlt, FaTag, FaInfo } from "react-icons/fa";
import API from "./../../../api/apiHandler";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import { Utilities } from "../../../Core/Utilities";
import { TimeBookingContext } from "../../../context/TimebookingContext";
import { parse } from "dotenv";
import InputPopup from "../../Timesafe/InputPopup";
import { TsDataContext } from "../TsDataContext";
import { TSURL_PROJEKT_TAETIGKEITEN } from "../../../config";
import { getWithToken } from "../../../tsapi/apiRequets";
import { GetTsApiBarerToken } from "../../../tsapi/tsapiToken";
import { requestEmployee, tsapiUserInfo } from "../../Timesafe/timesafeApiCalls";

function Task(props: {
    index: number;
    quoteId: string;
    task: ITask;
    indexPhase: number;
    workgroups: IWorkGroup[];
    quoteEditable: boolean;
}) {
    const [expand, setexpand] = useState(false);
    const [expandTs, setexpandTs] = useState(false);

    const [tsText, setTsText] = useState(""); 
    const [tsBookingDataComplete, setTsBookingDataComplete] = useState(false); 
    const notify = (text: string) => Utilities.printSuccess(text);

    const { isActive, contextTimeBookingData, startTimeBooking, setIsActive, setContextTimeBookingData } = useContext(TimeBookingContext);
    const { customerId, projectId, userId,token, setToken, setCustomerId, setProjectId,setUserId} = useContext(TsDataContext);

    function handleTaskChange(newTask: ITask) {
        Message.Send(EventMessage.EDIT_TASK, undefined, {
            indexPhase: props.indexPhase,
            indexTask: props.index,
            task: newTask
        });
    }

    function handleTextExternChange(textExtern: string) {
        let tbd = contextTimeBookingData;
        tbd.textExtern = textExtern;
        setContextTimeBookingData(tbd);
    }

    function loadVersion() : number {
        let version = localStorage.getItem("version");
        if(version === null) {
            version = "1";
            localStorage.setItem("version", version);
        }
        return Number(version);
    }

    async function handleOnCopyTag() {
        let response = await API.ID.GetTimeSafeTag(props.quoteId, props.task.Id);
        let a = JSON.parse(response.Id);
        const copyText = `[${props.task.Id}][COMPL=${a[1]}]`;
        navigator.clipboard.writeText(copyText);
        notify(`Copy id: ${copyText} successfull to clipboard`);
    }

    let wgList = props.workgroups.map((workgroup) => {
        return (
            <option key={workgroup.name} value={workgroup.name}>
                {workgroup.name}
            </option>
        );
    });

    const handleTextTsChange = (value: any) =>{
        setTsText(value);
        if(tsText !== ""){
            setTsBookingDataComplete(true);
        }
    };

    function onWorkerChange(event: any) {
        let worker = props.workgroups.find((workerGroup) => workerGroup.name === event.target.value);
        if (!worker) return;
        let newTask: ITask = {
            ...props.task,
            WorkerGroup: worker.name,
            HourlyRate: worker.hourlyRate
        };

        handleTaskChange(newTask);
    }

    function handleBtnClickExpand() {
        setexpand(!expand);
    }

    function stopProp(event: React.MouseEvent) {
        event.stopPropagation();
    };

    const handleTaskDelete = () => {
        console.log(props.indexPhase, props.index);
        Message.Send(EventMessage.DELETE_TASK, undefined, { indexPhase: props.indexPhase, indexTask: props.index });
    };


    const handleButtonClickStore = () => {
        console.log("button store timebooking clicked");
        let localTb = contextTimeBookingData;
        localTb.textExtern = tsText;
        setContextTimeBookingData(localTb);
        startTimeBooking();
        setexpandTs(false);
        setTsText("");
        setTsBookingDataComplete(false);
    };

    
    async function requestTaetigkeiten(token: string, projektId: number)
    {
        let parameter = {"ProjektID": projektId.toString(),"Aktive": "true"};

        let response = await getWithToken(token, `${TSURL_PROJEKT_TAETIGKEITEN}`, parameter).catch(
            (error: string) => {
                console.error(error);
            }
        );    
        let data = await response.json();

        return data.resultat;
    }
    


    const handleButtonClickStartStop = async () => {
        console.log("button start/stop timebooking clicked");

        if(!isActive){
            let timesafeB64 = localStorage.getItem("timesafe-b64");
            if(timesafeB64 === null){
                console.log("timesafe-b64 not found");
                return;
            }
            
            let token = await GetTsApiBarerToken(timesafeB64);
            let localTb = contextTimeBookingData;
            console.log(`local timebooking: ${JSON.stringify(localTb)}`);
            console.log(`customerId: ${customerId}`);
            console.log(`projectId: ${projectId}`);

            //TODO: TaetigkeitsId from qoute (select droupdown when generating qoute)
            //TODO: DB Change
            let projectTasks = await requestTaetigkeiten(token, projectId );
            console.log(`projekt tätigkeiten: ${JSON.stringify(projectTasks)}`);    
            console.log(`task description: ${props.task.WorkerGroup}`);
            projectTasks = projectTasks.filter((task: any) => task.aktiv===true);
            const matchingTask = projectTasks.find((task: any) => 
                task.bezeichnung.includes(props.task.WorkerGroup));
            if (matchingTask) {
                console.log("Found task:", matchingTask);
                localTb.taetigkeitID = matchingTask.id;
            } else {
                console.log("No matching task found");
                localTb.taetigkeitID = 0;
                setTsText("!! Timebooking not startet. No matching (active) task found !!");
                //TODO: Error message for user, button not clickable
                setexpandTs(true);
                return;
            }

            let employeeData : tsapiUserInfo = await requestEmployee(token);
            localTb.mitarbeiterID = employeeData.id ; 
            localTb.textIntern = props.task.Id.toString(); 
            console.log(`local timebooking: ${JSON.stringify(localTb)}`);
            setContextTimeBookingData(localTb);
            
            startTimeBooking();
        }
        else{
            setexpandTs(true);
        }
    };

    function TimesafeStartStopBtn() {
        let version = loadVersion();
        if(version === 1){
            return (
                <div>
                    <div className="task__tsId">{`${props.task.Id}`}</div>
                </div>
            );
        }
        else {
            if(isActive){
                if(String(props.task.Id) === contextTimeBookingData.textIntern){
                    return (
                        <button
                            style={{
                                backgroundColor: isActive ? "orange" : "green",
                                color: "white",
                                padding: "2px 2px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "24px"
                            }}
                            onClick={handleButtonClickStartStop}>
                            {isActive ? "◻" : "▷"}
                        </button>
                    );
                }
                else{
                    return(
                        <button
                            style={{
                                backgroundColor: "gray",
                                color: "white",
                                padding: "2px 2px",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "24px"
                                }}
                            onClick={handleButtonClickStartStop}>
                            {isActive ? "◻" : "▷"}
                        </button>);
                }
            }
            else{
                return (
                    <button
                        style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: "2px 2px",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "24px"
                        }}
                        onClick={handleButtonClickStartStop}>
                        {isActive ? "◻" : "▷"}
                    </button>
                );
            }
        }
    }

    let costPriceTemplate = () => (
        <>
            <div className="body__exapand-copy" onClick={handleOnCopyTag}>
                <FaTag></FaTag>
            </div>
            {props.quoteEditable && (
                <div className="body__exapand-delete" onClick={handleTaskDelete}>
                    <FaTrashAlt></FaTrashAlt>
                </div>
            )}
            <div className="task__header">
                <div className=" task__id">{`${props.indexPhase + 1}.${props.index + 1}`}</div>
                
                <TimesafeStartStopBtn/>   
  
                <button
                    style={{
                        backgroundColor: "gray",
                        color: "white",
                        padding: "2px 2px",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "24px"
                    }}
                    onClick={handleBtnClickExpand}>
                    {expand ? "▴" : "▾"}
                </button>

                <span className="task__item task__title">
                    <input
                        onClick={stopProp}
                        disabled={!props.quoteEditable}
                        type="text"
                        value={props.task.Title}
                        onChange={(e) => handleTaskChange({ ...props.task, Title: e.target.value })}
                    />
                    
                    {props.task.Description && (
                        <div className="has-info">
                            <FaInfo className="has-info-icon"></FaInfo>
                            <div className="has-info-text">{props.task.Description}</div>
                        </div>
                    )}
                </span>

                

                <select
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    className="task__item task__workgroup"
                    onChange={(e) => onWorkerChange(e)}
                    value={props.task.WorkerGroup || 0}>
                    {wgList}
                </select>
                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    className="task__item task__duration"
                    type="number"
                    value={secondsToHour(props.task.Duration)}
                    onChange={(e) => handleTaskChange({ ...props.task, Duration: Number(e.target.value) * 3600 })}
                />

                <div className="task__unit">h</div>

                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    type="number"
                    className="task__item task__hourly-rate"
                    value={props.task.HourlyRate}
                    onChange={(e) =>
                        handleTaskChange({ ...props.task, HourlyRate: Number.parseInt(e.target.value) })
                    }></input>
                <div className=" task__total">
                    {`CHF ${(props.task.HourlyRate * secondsToHour(props.task.Duration)).toFixed(2)}`}
                </div>
            </div>
            {expand && (
                <div className="task__body">
                    <textarea
                        disabled={!props.quoteEditable}
                        className="task--description"
                        rows={1}
                        cols={60}
                        value={props.task.Description}
                        onChange={(e) => handleTaskChange({ ...props.task, Description: e.target.value })}></textarea>
                    <input
                        disabled={!props.quoteEditable}
                        className="body__exapand-date"
                        type="date"
                        value={dateStringToInputDate(props.task.Date)}
                        onChange={(e) =>
                            handleTaskChange({ ...props.task, Date: InputDateTodateString(e.target.value) })
                        }></input>

                    <div className="body__exapand-less" onClick={() => close()}>
                        <MdExpandLess></MdExpandLess>
                    </div>
                </div>
            )}
            {expandTs && (
                <div className="tstext__body">
                    <textarea
                        className="tstext--description"
                        rows={1}
                        cols={60}
                        value={tsText}
                        onChange={(e) => handleTextTsChange(e.target.value)}></textarea>
                    <button
                    style={{
                        backgroundColor: tsBookingDataComplete ? "green" : "gray",
                        color: "white",
                        padding: "2px 2px",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "14px"
                    }}
                    onClick={handleButtonClickStore}>
                    {tsBookingDataComplete ? "BOOK" : "!TEXT"}
                </button>
                </div>
            )}
        </>
    );

    let fixPriceTemplate = () => (
        <>
            <div className="body__exapand-copy" onClick={handleOnCopyTag}>
                <FaTag></FaTag>
            </div>
            {props.quoteEditable && (
                <div className="body__exapand-delete" onClick={handleTaskDelete}>
                    <FaTrashAlt></FaTrashAlt>
                </div>
            )}
            <div className="task__header" onClick={() => open()}>
                <div className=" task__id">{`${props.indexPhase + 1}.${props.index + 1}`}</div>
                <span className="task__item task__title">
                    <input
                        disabled={!props.quoteEditable}
                        onClick={stopProp}
                        type="text"
                        value={props.task.Title}
                        onChange={(e) => handleTaskChange({ ...props.task, Title: e.target.value })}
                    />
                    {props.task.Description && (
                        <div className="has-info">
                            <FaInfo className="has-info-icon"></FaInfo>
                            <div className="has-info-text">{props.task.Description}</div>
                        </div>
                    )}
                </span>
                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    type="number"
                    min="0"
                    className="task__item task__hourly-rate"
                    value={props.task.HourlyRate}
                    onChange={(e) =>
                        handleTaskChange({ ...props.task, HourlyRate: Number.parseInt(e.target.value) })
                    }></input>
                <div className=" task__total">{props.task.HourlyRate.toFixed(2)}</div>
            </div>
            {expand && (
                <div className="task__body">
                    <textarea
                        disabled={!props.quoteEditable}
                        className="task--description"
                        rows={1}
                        cols={60}
                        value={props.task.Description}
                        onChange={(e) => handleTaskChange({ ...props.task, Description: e.target.value })}></textarea>
                    <input
                        disabled={!props.quoteEditable}
                        className="body__exapand-date"
                        type="date"
                        value={dateStringToInputDate(props.task.Date)}
                        onChange={(e) =>
                            handleTaskChange({ ...props.task, Date: InputDateTodateString(e.target.value) })
                        }></input>

                    <div className="body__exapand-less" onClick={() => close()}>
                        <MdExpandLess></MdExpandLess>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <>
            <Draggable
                isDragDisabled={!props.quoteEditable}
                draggableId={`task-${props.indexPhase}-${props.index}`}
                index={props.index}
                key={`task-${props.indexPhase}-${props.index}`}>
                {(provided) => (
                    <div
                        id={props.task.Id}
                        className="quoteedit__task"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}>
                        {(props.task.TaskType === 0 || props.task.TaskType === 1) && costPriceTemplate()}
                        {props.task.TaskType === 2 && fixPriceTemplate()}
                    </div>
                )}
            </Draggable>
        </>
    );
}

export default Task;
