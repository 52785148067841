import React, { useEffect, useState, useContext } from "react";
import { IProject } from "../../interfaces/IProject";
import API from "../../api/apiHandler";
import moment from "moment";
import "moment/locale/de";
import "./style.scss";
import Select from "react-select";
import { IIdString } from "../../interfaces/IIdString";
import { ITsProjectPhases } from "../../interfaces/ITsProjectPhases";
import { ICustomer } from "../../interfaces/ICustomer";
import { getWithToken } from "../../tsapi/apiRequets";
//import { getToken } from "../../tsapi/Auth";
import { GetTsApiBarerToken } from "../../tsapi/tsapiToken";
import { TSURL_MITARBEITER, TSURL_PROJEKT_TAETIGKEITEN } from "../../config";
import { TimeBookingContext, TimeBookingProvider } from "../../context/TimebookingContext";

import { EMPTY_TSTAETIGKEIT, ITsTaetigkeit } from "../../interfaces/ITsTaetigkeit";
import { requestEmployee, tsapiUserInfo } from "./timesafeApiCalls";

function TimesafeContainer() {
    moment.locale("de");

    const [organization, setOrganization] = useState<[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<{}>({});

    const [project, setProject] = useState<[]>([]);
    const [selectedProject, setSelectedProject] = useState<{}>({});

    const [customers, setCustomers] = useState<ICustomer[]>([]);

    const [employee, setEmployee] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [taetigkeitId, setTaetigkeitId] = useState<number>(0);

    const [timebookingText, setTimeBookingText] = useState<string>("");

    const [selectedProjectId, setSelectedProjectId] = useState<number>(0);

    /*
    let { setIsActive } = useContext(TimeBookingContext);
    let { isActive, setTimeBooking} = useContext(TimeBookingContext);  
    let { contextTimeBookingData, setContextTimeBookingData } = useContext(TimeBookingContext);
*/
    const { isActive, contextTimeBookingData, startTimeBooking, setIsActive, setContextTimeBookingData } = useContext(TimeBookingContext);

    //const [TimeText, setTimeText] = useState<string>("");
    let timebookingStr = "";

    let tsTaetigkeit : ITsTaetigkeit = EMPTY_TSTAETIGKEIT;

    const handleButtonClick = () => {
        console.log("button start/stop timebooking clicked");

        let localTb = contextTimeBookingData;
        console.log(`local timebooking: ${JSON.stringify(localTb)}`);
        localTb.mitarbeiterID = parseInt(employeeId);
        localTb.taetigkeitID = taetigkeitId;
        localTb.textExtern = timebookingText;

        setContextTimeBookingData(localTb);
        
        startTimeBooking();
    };
    //TODO: Get Open Timebooking from database

    //Check if active-timebookin in settings, write if not
    if (localStorage.getItem("active-timebooking") === null) {
        timebookingStr = JSON.stringify(contextTimeBookingData);
        localStorage.setItem("active-timebooking", timebookingStr);
    }

    async function getOrganizations() {
        let organizations = await API.TimeSafeFunctions.getAllTimeSafeOrganizations();
        setOrganization(organizations); /*
        let organization : [IOrganization];
        customer.forEach(e => {
            organization.pop(e)
        });*/
    }

    async function getCustomerData() {
        let customer = await API.Customer.getAllCustomerData().catch((error: string) => {
            console.error(error);
        });
        setCustomers(customer);
    }

    const updateEmployee = (employee: string) => {
        const updatedEmployee = employee;

        setEmployee(updatedEmployee);
    };

    const updateEmployeeId = (employee: string) => {
        const updatedEmployee = employee;

        setEmployeeId(updatedEmployee);
    };

    async function getProjects(orgId: string | null) {
        let proj = (await API.Projects.getProjects(null, null, orgId, null)) as [IProject];
        //let projects : [{value: string, label: string}] ;
        let projects: [{ value: string; label: string }] = [{ value: "0", label: "" }];

        proj.forEach((p) => {
            projects.push({ value: `${p.ProjectId}`, label: p.ProjectName });
        });
        setProject(projects as any);
    }

    console.log(project);

    useEffect(() => {
        getCustomerData();
        console.log(customers);
        getOrganizations();

        //read active-timebooking from settings and check if empty or already started
        let a = localStorage.getItem("active-timebooking");
        if (a != null) {
            timebookingStr = a as string;
            setContextTimeBookingData(JSON.parse(timebookingStr));

            //fill the forms with open timebooking
            if (contextTimeBookingData.zeitVonInMinuten > 0) {
                setIsActive(true); 
            }
        }
        if (contextTimeBookingData.mitarbeiterID != 0) {
            console.log(`open timebooking:${timebookingStr}`);
        }
    }, []);

    useEffect(() => {
        console.log(`triggeret contextupdate: ${JSON.stringify(contextTimeBookingData)}`);
    }, [contextTimeBookingData]);

    useEffect(() => {
        console.log(`triggeret contextupdate isActive: ${JSON.stringify(isActive)}`);
    }, [isActive]);

    let proList = project.map((item: { value: string; label: string }) => {
        return {
            value: item.value,
            label: item.label
        };
    });

    let orgList = organization.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    async function handleOnChangeOrganization(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;

        let orgString = event.value;
        setSelectedOrganization({ label: orgString, value: orgString });

        let organizId = 0;
        let org = (await API.TimeSafeFunctions.getAllTimeSafeOrganizations()) as [IIdString];
        org.forEach((o) => {
            if (o.String === orgString) {
                organizId = o.Id;
            }
        });
        console.log(organizId);
        getProjects(organizId.toString());
    }

    async function getEmployee() {
        console.log("Get employee information");
        let timesafeB64 = localStorage.getItem("timesafe-b64");
        if(timesafeB64 === null){
            console.log("timesafe-b64 not found");
            return;
        }
        const token = await GetTsApiBarerToken(timesafeB64);
        
        //TODO: secret from browser or database

        console.log(`ts-token readed:${token}`);

        let employeeData : tsapiUserInfo = await requestEmployee(token);

        updateEmployee(employeeData.login);

        updateEmployeeId(employeeData.id.toString());

        /*
        return new Promise<any>(async (resolve, reject) => {
         const response = await getWithToken(token, `${TSURL_MITARBEITER}AngemeldeterBenutzer`, parameter) // force break
            .catch((error: string) => reject(error));
        
            if (response.ok) {
                let body = await response.json();
                resolve(body);
            } else {
                reject(response.statusText);
            }
        });*/
    }
    
    async function getTaetigkeiten() {
        console.log("Get employee information");
        let timesafeB64 = localStorage.getItem("timesafe-b64");
        if(timesafeB64 === null){
            console.log("timesafe-b64 not found");
            return;
        }
        const token = await GetTsApiBarerToken(timesafeB64);
        //TODO: secret from browser or database

        console.log(`ts-token readed:${token}`);
        console.log(`selectedProjectid: ${selectedProjectId}`);
        console.log(`selectedProject: ${JSON.stringify(selectedProject)}`);

        let projectTasks = await requestTaetigkeiten(token, selectedProjectId );
        console.log(`projekt tätigkeiten: ${projectTasks}`);    
        
        let defaultTask = projectTasks[0];
        console.log(`tätigkeit default: ${JSON.stringify(defaultTask)}`);
        tsTaetigkeit = defaultTask as ITsTaetigkeit;
        setTaetigkeitId(tsTaetigkeit.id);
    }

    

    

    async function requestTaetigkeiten(token: string, projektId: number)
    {
        let parameter = {"ProjektID": projektId.toString(),"Aktive": "true"};

        let response = await getWithToken(token, `${TSURL_PROJEKT_TAETIGKEITEN}`, parameter).catch(
            (error: string) => {
                console.error(error);
            }
        );    
        let data = await response.json();

        return data.resultat;
    }

    async function handleOnChangeProject(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;
        let selProjectString = event.label;
        setSelectedProject({ label: selProjectString, value: selProjectString });

        let projList = (await API.Projects.getProjects(null, null, null, null)) as [
            { DateTimeEnd: string; DateTimeStart: string; Id: number; ProjectName: string; ProjectNo: string }
        ];
        let projId = "";
        projList.forEach((c) => {
            if (c.ProjectName === selProjectString) {
                setSelectedProjectId(c.Id);
                console.log(`selectedProjectid: ${selectedProjectId}`);
                projId = `${c.Id}`;
            }
        });
    }

    return (
        <div className="timesafe--container">
            <div id="TimesafeBlock">
                <label id="TimesafeBlockTitel">TIMESAFE DETAILS</label>
                
                    <div id="TimesafeTableBox">
                        <label>ORGANIZATION:</label>
                        <Select
                            className="select"
                            options={orgList}
                            value={selectedOrganization}
                            onChange={(event: any) =>
                                handleOnChangeOrganization({ label: event.label, value: event.value })
                            }
                        />
                    </div>

                    <div id="TimesafeTableBox">
                        <label>PROJECT:</label>
                        <Select
                            className="select"
                            options={proList}
                            value={selectedProject}
                            onChange={(event: any) =>
                                handleOnChangeProject({ label: event.label, value: event.value })
                            }
                        />
                    </div>
                    
                    <div id="TimesafeTableBox">
                        <label>Text:</label>
                        <div className="input">
                            <input
                                autoComplete="off"
                                type="text"
                                name="gender"
                                value={timebookingText}
                                onChange={(e) => setTimeBookingText(e.target.value)}
                            />
                            <label htmlFor="male">Tag for quoteestimation</label>
                        </div>
                    </div>


                    <button id="Button" onClick={getEmployee}>
                        MITARBEITER
                    </button>
                    <button id="Button" onClick={getTaetigkeiten}>
                        TÄTIGKEITEN
                    </button>

                    <label id="employeeTitle">Mitarbeiter: </label>
                    <label id="employee">{employee}</label>
                    <br></br>
                    <label id="employeeTitle">Id: </label>
                    <label id="employee">{employeeId}</label>
                    <br></br>
                    <br></br>
                    <label id="employeeTitle">Tätigkeit: </label>
                    <label id="employee">{taetigkeitId.toString()}</label>


                    <button
                        style={{
                            backgroundColor: isActive ? "orange" : "green",
                            color: "white",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px"
                        }}
                        onClick={handleButtonClick}>
                        {isActive ? "STOP" : "START"}
                    </button>
            </div>
        </div>
    );
}

export default TimesafeContainer;




