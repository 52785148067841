//predefine class for user pw 
export interface UserPw {
    user: string;
    pw: string;
}


export function encodeBase64(data: string) {
    var legacy = require("legacy-encoding");
    let buff = legacy.encode(data,"windows-1250");  //iso-8859-2
    let base64data = buff.toString("base64");
    console.log(`encoded: ${base64data}`);
    return base64data;
}

export function decodeBase64(data: string) {
    var iconv = require("iconv-lite");
    let buff = new Buffer(data, "base64");
    var str = iconv.decode(buff, "windows-1250");
    //let str = windows1250.decode(buff);
    console.log(`decoded: ${str}`);
    return str;
}

export function encodeTimesafeUserPw(user: string, pw: string) {
    return encodeBase64(`${user}:${pw}`);
}

export function decodeTimesafeBase64toUserPw(base64: string) {
    let dec = decodeBase64(base64);
    //split string at first occurence of ":"
    let user = dec.split(":",1);
    let pw = dec.substring(user[0].length+1);
    let userpw : UserPw = {user: user, pw: pw};
    return userpw;
}

