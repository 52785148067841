import React from "react";


export const TsDataContext = React.createContext({
    projectId: 0,
    userId: 0,
    token: "",
    customerId: 0,
    setProjectId: (value : number) => { },
    setUserId: (value : number) => { },
    setToken: (value : string) => { },
    setCustomerId: (value : number) => { }
});

export const TsDataProvider = ({ children }: { children: any }) => {
    const [projectId, setProjectId] = React.useState<number>(0);
    const [userId, setUserId] = React.useState<number>(0);
    const [token, setToken] = React.useState<string>("");
    const [customerId, setCustomerId] = React.useState<number>(0);

    return (
        <TsDataContext.Provider value={{ 
            projectId,
            userId,
            token,
            customerId,
            setProjectId,
            setUserId,
            setToken,
            setCustomerId
            }}>
            {children}
        </TsDataContext.Provider>
    );
};


