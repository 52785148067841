import { useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../../api/apiHandler";
import { dateStringToInputDate, secondsToHour } from "../../Utils/Utilities";
import moment from "moment";
import "moment/locale/de";
import { IProject } from "../../interfaces/IProject";
import { ITimeSafeV1Entry } from "../../interfaces/ITimeSafeV1Entry";
import "./style.scss";
import { decodeBase64, encodeBase64, encodeTimesafeUserPw } from "../Timesafe/timesafeFunctions";

let ENTRY = styled.div`
    text-align: left;
`;

let CONTENT = styled.div`
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex: 1;
`;
let TABLE = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

function ThanosV1Container() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [projects, setProjects] = useState<IProject[]>([]);
    moment.now();
    let d1 = moment().subtract(7, "d").format("YYYY-MM-DD");
    let d2 = moment().format("YYYY-MM-DD");
    console.log(d1, d2);
    const [dateFrom, setDateFrom] = useState<string>(d1);
    const [dateTo, setDateTo] = useState<string>(d2);
    const [keys, setKeys] = useState<string[]>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<number>(-1);
    const [entrys, setEntrys] = useState<ITimeSafeV1Entry[]>([]);
    const [secretString, setSecretString] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [base64, setBase64] = useState<string>("");

    async function getProjects() {
        if (account) {
            let loadProjects = await API.Projects.getProjects(null, null, null, null) as IProject[];    
            let projects : IProject[];
            projects = loadProjects.sort((a,b)=> a.ProjectName.localeCompare(b.ProjectName));
            setProjects(projects);
        }
    }

    async function getEntrySummary(projectId: number) {
        let keys = await API.EntrySummary.getEntrySummary(
            dateStringToInputDate(dateFrom),
            dateStringToInputDate(dateTo),
            projectId
        );
        setKeys(keys);
    }
    async function getProjectEntrys(projectId: number) {
        if (account) {
            let tasks = await API.ProjectEntrys.getProjectEntrys(
                instance,
                account,
                dateStringToInputDate(dateFrom),
                dateStringToInputDate(dateTo),
                projectId
            );
            setEntrys(tasks);
        }
    }

    async function showNamePw(base64: string) {
        let dec = decodeBase64(base64);
        //split string at first occurence of ":"
        let name = dec.split(":",1);
        let pw = dec.substring(name[0].length+1);
        setName(name);
        setPassword(pw);
    }
    
    async function showBase64(name: string, pw: string) {
        let str = encodeTimesafeUserPw(name,pw);
        setBase64(str);
    }

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        getEntrySummary(selectedProjectId);
        getProjectEntrys(selectedProjectId);
    }, [selectedProjectId, dateFrom, dateTo]);

    let projectList = projects.map((projects: any) => (
        <option key={projects.Id} value={projects.Id}>
            {projects.ProjectName}
        </option>
    ));

    let keyless = entrys.filter((entry: ITimeSafeV1Entry) => entry.Id === "");

    let keylesslist = keyless.map((key, index) => (
        <div key={index}>
            <strong>{key.Employee} </strong> doesn't like Tags{" "}
            <strong>{secondsToHour(key.Duration).toFixed(2)}h</strong>
            <pre>{key.Entry}</pre>
        </div>
    ));
    
    function handleOnChangeName(event: any) {
        if (event.target.value === "") return;
        let inName = event.target.value;
        setName(inName);
    }

    function handleOnChangePassword(event: any) {
        if (event.target.value === "") return;
        let inPw = event.target.value;
        setPassword(inPw);
    }

    function handleOnChangeBase64(event: any) {
        if (event.target.value === "") return;
        let inBase64 = event.target.value;
        setName(inBase64);
    }
    

    function handleOnChangeDateFrom(event: any) {
        if (event.target.value === "") return;
        let inDate = event.target.value;
        console.log("datelog");
        console.log(inDate);
        setDateFrom(inDate);
    }

    function handleOnChangeDateTo(event: any) {
        if (event.target.value === "") return;
        let inDate = event.target.value;
        console.log("datelog");
        console.log(inDate);
        setDateTo(inDate);
    }

    function handleSelectChange(event: any) {
        setSelectedProjectId(event.target.value);
    }

    let list = Array.from(Object.entries(keys));
    let keyList = list.map((key: any) => (
        <ENTRY key={key[0]}>
            {key[0]} &nbsp;&nbsp;&nbsp;&nbsp; {secondsToHour(key[1]).toFixed(2)}h
        </ENTRY>
    ));

    return (
        <div className="thanosV1--container">
            <select onChange={handleSelectChange}>{projectList}</select>
            FROM:
            <input type="date" onChange={handleOnChangeDateFrom} value={dateStringToInputDate(dateFrom)}></input>
            TO:
            <input type="date" onChange={handleOnChangeDateTo} value={dateStringToInputDate(dateTo)}></input>
            <CONTENT>
                <TABLE>{keyList}</TABLE>
                <TABLE>{keylesslist}</TABLE>
            </CONTENT>


            <br></br>
            <label>Base64 enode/decode for timesafe (windows-1250)</label>
            <label>insert either name and pw, or base64</label>
            <table>
                <tr>
                    <th>Name</th>
                    <td><input type="name" onChange={handleOnChangeName} value={name} ></input></td>
                </tr>
                <tr>
                    <th>Password</th>
                    <td><input type="name" onChange={handleOnChangePassword} value={password}></input></td>
                    
                </tr>
                <tr>
                    <th>Base64</th>
                    <td><input type="base64" onChange={handleOnChangeBase64} value={base64}></input></td>
                </tr>
            </table>
            <br></br>

            <button onClick={() => showBase64(name,password)}>Get base64</button>
            <button onClick={() => showNamePw(base64)}>Get NamePw</button>

        </div>
    );
}

export default ThanosV1Container;
