import React, {useState, useEffect, useContext} from "react";
import { GetTsApiBarerToken } from "../tsapi/tsapiToken";
import { TSURL_MITARBEITER, TSURL_ZEITEINTRAEGE } from "../config";
import { EMPTY_TIMEBOOKING, ITimeBooking } from "../interfaces/ITimeBooking";
import { getWithToken, postWithToken } from "../tsapi/apiRequets";



    // mitarbeiterID: number;  // bei start
    // tätigkeitID: number;    
    // istStempeleintrag: boolean;
    // datum: string;
    // zeitVonInMinuten: number;
    // zeitBisInMinuten: number;
    // dauerInMinuten: number;
    // dauerVerrechenbarInMinuten: number;
    // textExtern: string;
    // textIntern: string;



export const TimeBookingContext = React.createContext({
    isActive: false,
    contextTimeBookingData: EMPTY_TIMEBOOKING,
    startTimeBooking: () => { }, 
    setIsActive: (value :boolean) => { },
    setContextTimeBookingData: (data : ITimeBooking) => { }
});

export const TimeBookingProvider = ({ children }: { children: any }) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [contextTimeBookingData, setContextTimeBookingData] = useState<ITimeBooking>(EMPTY_TIMEBOOKING);

    const setIsActiveTest = (value : boolean) : void => {
            setIsActive(value);
    };

    
/*

    useEffect(() => { 
        setIsActiveTest(false);
        setContextTimeBookingData(EMPTY_TIMEBOOKING);
    },[]);*/

    useEffect(() => { 
            console.log(`Context updated: {${JSON.stringify(contextTimeBookingData)}}`);
    },[contextTimeBookingData]);
    

    async function requestEmployee(token: string) {
        let parameter = {};

        let response = await getWithToken(token, `${TSURL_MITARBEITER}/AngemeldeterBenutzer`, parameter).catch(
            (error: string) => {
                console.error(error);
            }
        );

        let data = await response.json();

        return data.resultat;
    };
    

    function getTimeTodayInMin(date : Date) : number {
        return date.getMinutes() + date.getHours() * 60;
    };

    async function getTimeBookingStopData(openTimebooking : ITimeBooking, text : string) : Promise<ITimeBooking>{
        let date = new Date(Date.now());
        let timeNowInMin = getTimeTodayInMin(date);
        let ts_datum = date.toISOString();
        
        let dateStart = new Date(openTimebooking.datum);
        let timeStartInMin = getTimeTodayInMin(dateStart);
        console.log(`tb stop: ${timeNowInMin}`);
        openTimebooking.zeitBisInMinuten = timeNowInMin;
        openTimebooking.dauerInMinuten = timeNowInMin - timeStartInMin; 
        openTimebooking.textExtern = text;  
        return openTimebooking;
    };

    function getTimeBookingStartData(mitarbeiterId : number, taetigkeitId: number, text : string, textIntern : string) : ITimeBooking {
        let date = new Date(Date.now());
        let timeNowInMin = getTimeTodayInMin(date);
        let ts_datum = date.toISOString();
        console.log(`tb start: ${timeNowInMin}`);
        return {
            mitarbeiterID: mitarbeiterId,
            taetigkeitID: taetigkeitId,
            istStempeleintrag: false,
            datum: ts_datum,
            zeitVonInMinuten: timeNowInMin,
            zeitBisInMinuten: 0,
            dauerInMinuten: 0,
            dauerVerrechenbarInMinuten: 0,
            textExtern: text,
            textIntern: textIntern
        };
    };

    function triggerUpdateContext(data : ITimeBooking) {
        setContextTimeBookingData(data);
    }

    async function startTimeBooking() {
        let timesafeB64 = localStorage.getItem("timesafe-b64");
        if(timesafeB64 === null){
            console.log("timesafe-b64 not found");
            return;
        }
        const token = await GetTsApiBarerToken(timesafeB64);
        console.log(`ts-token readed:${token}`);

        if (isActive === true) {
            console.log("Timebooking already started");
            let tbd = await getTimeBookingStopData(contextTimeBookingData, contextTimeBookingData.textExtern);
            triggerUpdateContext(tbd);
            console.log(`timebooking stopped, object: {${JSON.stringify(contextTimeBookingData)}}`);
            
            let postObject : any;
            postObject = tbd;
            delete postObject.dauerInMinuten;  //timesafe only allows (starttime + duration) OR (starttime + endtime)
            delete postObject.dauerVerrechenbarInMinuten;  //timesafe only allows (starttime + duration) OR (starttime + endtime)
            let parameter = {};
            console.log(`postObject: {${JSON.stringify(postObject)}}`);
            postWithToken(token, `${TSURL_ZEITEINTRAEGE}`,parameter,postObject).catch(  (error: string) => {
                console.error(error);
            });
            
            triggerUpdateContext(EMPTY_TIMEBOOKING);
            setIsActive(false);
        }
         else {
            //Timebooking start -> Store Timestamp and data locally
            //TODO: Store into DB
            let tbd = getTimeBookingStartData(
                contextTimeBookingData.mitarbeiterID,
                contextTimeBookingData.taetigkeitID,
                contextTimeBookingData.textExtern,
                contextTimeBookingData.textIntern
            );
            console.log("timebooking started");
            console.log(`Object got: {${JSON.stringify(tbd)}}`);
            triggerUpdateContext(tbd);
            console.log(`Object stored: {${JSON.stringify(contextTimeBookingData)}}`);

            let timebookingStr = JSON.stringify(contextTimeBookingData);
            localStorage.setItem("active-timebooking", timebookingStr);
            setIsActive(true);
        }

        return;
    }

    return (
        <TimeBookingContext.Provider value={{ 
            isActive, 
            contextTimeBookingData,
            startTimeBooking, 
            setIsActive, 
            setContextTimeBookingData,
            }}>
            {children}
        </TimeBookingContext.Provider>
    );
  };
;
