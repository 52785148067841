export interface ITimeBooking {
    mitarbeiterID: number;  // bei start
    taetigkeitID: number;    
    istStempeleintrag: boolean;
    datum: string;
    zeitVonInMinuten: number;
    zeitBisInMinuten: number;
    dauerInMinuten: number;
    dauerVerrechenbarInMinuten: number;
    textExtern: string;
    textIntern: string;
};

export const EMPTY_TIMEBOOKING : ITimeBooking = ({
    mitarbeiterID: 0,
    taetigkeitID: 0,
    istStempeleintrag: false,
    datum: "",
    zeitVonInMinuten: 0,
    zeitBisInMinuten: 0,
    dauerInMinuten: 0,
    dauerVerrechenbarInMinuten: 0,
    textExtern: "",
    textIntern: ""
});
