import { TSURL_MITARBEITER } from "../../config";
import { getWithToken } from "../../tsapi/apiRequets";
import tsapiUserInfo from "../../tsapi/tsapiUserInfo";

export async function requestEmployee(token: string) {
    let parameter = {};

    let response = await getWithToken(token, `${TSURL_MITARBEITER}/AngemeldeterBenutzer`, parameter).catch(
        (error: string) => {
            console.error(error);
        }
    );

    let data = await response.json();
    let returnData: tsapiUserInfo = data.resultat;
    return returnData;
}


/**
 * Get the user information from the TimeSafe API
 * @param token The token to authenticate with the API
 * @returns The user information
 */
export interface tsapiUserInfo {
    id: number;
    name: string;
    vorname: string;
    orgEinheitID: number;
    orgEinheitBezeichnung: string;
    personalNr: number;
    login: string;
    kostenstelleID: number;
    kostenstelleBezeichnung: string;
    kostenstelleNr: string;
    adresse1: string;
    adresse2: string;
    plz: string;
    ort: string;
    land: string;
    telefonDirekt: string;
    telefonIntern: string;
    telefonPrivat: string;
    telefonMobil: string;
    eMailGeschaeft: string;
    eMailPrivat: string;
    geburtsdatum: Date;
    buergerort: string;
    ahvSvNummer: string;
    aktiv: boolean;
    eintrittsdatum: Date;
    austrittsdatum: Date;
}


export interface tsapiFehlermeldung {
    nachricht: string;
    beispielObject: string;
}
