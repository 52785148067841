import DashboardContainer from "../Container";
import "./style.scss";
import React, { useContext, useEffect } from "react";
import { TimeBookingContext } from "../../../context/TimebookingContext";
import { EMPTY_TIMEBOOKING } from "../../../interfaces/ITimeBooking";

function handleButtonClickOpenTb() {
    //TODO: Jump to offer with open timebooking
    //TODO: Get offer id and version from timebooking
    /*
    const currentUrl = window.location.href;
    const url = `${currentUrl}/${OFFERID}/${OFFERVERSION}`; 
    window.location.href = url*/
    console.log("not implemented yet");
}

function getTimeTodayInMin(date : Date) : number {
    return date.getMinutes() + date.getHours() * 60;
};

function getTimeStringFromMin(min : number) : string {
    let hours = Math.floor(min / 60);
    let minutes = min % 60;

    return `${hours}:${minutes}` ;
}

function getTimeStartToNow(inDate : Date) {
    let date = new Date(Date.now());
    let timeNowInMin = getTimeTodayInMin(date);
    let ts_datum = date.toISOString();
    
    let timeStartInMin = getTimeTodayInMin(inDate);
    return (timeNowInMin - timeStartInMin);
};

function HandleTimeBooking() {
    const { isActive, contextTimeBookingData, startTimeBooking, setIsActive, setContextTimeBookingData } = useContext(TimeBookingContext);
    const [timeBookingData, setTimeBookingData] = React.useState(contextTimeBookingData);

    useEffect(() => {
        let a = localStorage.getItem("active-timebooking");
        
        if (a == null) {
            localStorage.setItem("active-timebooking", JSON.stringify(EMPTY_TIMEBOOKING));
            return;
        }
        else {
            let timebookingStr = a as string;
            let timebooking = JSON.parse(timebookingStr);
            setContextTimeBookingData(timebooking);
    
            //fill the forms with open timebooking
            if (timebooking.zeitVonInMinuten > 0) {
                setIsActive(true); 
            }
        }
    }, []);

    if (isActive) {
        return (
            <button className="timebooking__show" 
                style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "2px 2px",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "24px"
                    }} 
                    onClick={handleButtonClickOpenTb}>
                <label  className="label">Eingestempelt seit: {timeBookingData.datum}</label>
                <label  className="label">Offene Buchung {getTimeStringFromMin(getTimeStartToNow(new Date(timeBookingData.datum)))}</label>
            </button>
        );
    } 
    return (
        <div className="timebooking__show"
            style={{
                backgroundColor: "gray",
                color: "white",
                padding: "2px 2px",
                border: "none",
                borderRadius: "5px",
                fontSize: "24px"
                }}>
            <span className="label">Keine offene Buchung</span>
        </div>
    );
}

function ContainerTsBooking(props: { classes: string}) {
    return (
        <DashboardContainer headerName="TIMEBOOKING" className={`ds-timebooking ${props.classes}`}>
            <div className="timebooking__show">
                <HandleTimeBooking/>
            </div>
        </DashboardContainer>
    );
}

export default ContainerTsBooking;
